import React, { Component, useEffect } from "react";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Switch,
  useLocation,
  withRouter,
} from "react-router-dom";
import "./scss/style.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const TheLayoutWhite = React.lazy(() => import("./containers/TheLayoutWhite"));
const TheLayoutWhiteSimple = React.lazy(() =>
  import("./containers/TheLayoutWhiteSimple")
);
const TheLayoutWhiteLogin = React.lazy(() =>
  import("./containers/TheLayoutWhiteLogin")
);
const TheLayoutAdmin = React.lazy(() => import("./containers/TheLayoutAdmin"));
const TheLayoutUserAdmin = React.lazy(() =>
  import("./containers/TheLayoutUserAdmin")
);
const TheLayoutManage = React.lazy(() =>
  import("./containers/TheLayoutManage")
);
const PlainLayout = React.lazy(() => import("./containers/PlainLayout"));

// Pages
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

class App extends Component {
  render() {
    return (
      <div>
        <ToastContainer bodyClassName="CardSub" />
        <HashRouter>
          <ScrollToTop>
            <React.Suspense fallback={loading}>
              <Switch>
                {/* The Layout */}

                <Route
                  exact
                  path="/Dashboard"
                  render={(props) => <TheLayout {...props} />}
                />
                <Route
                  exact
                  path="/FinancialAnalysis"
                  render={(props) => (
                    <TheLayout title="financial analysis" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/FinancialHighlights"
                  render={(props) => (
                    <TheLayout title="Financial Highlights" {...props} />
                  )}
                />

                <Route
                  exact
                  path="/SalesPerformanceA"
                  render={(props) => (
                    <TheLayout title="Sales Performance A" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/SalesPerformanceB"
                  render={(props) => (
                    <TheLayout title="Sales Performance B" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/SalesPerformanceC"
                  render={(props) => (
                    <TheLayout title="Sales Performance C" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/FinancialAnalysis"
                  render={(props) => (
                    <TheLayout title="Financial Analysis" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/Pipeline"
                  render={(props) => <TheLayout title="Pipeline" {...props} />}
                />
                <Route
                  exact
                  path="/PipelineTable"
                  render={(props) => (
                    <TheLayout title="Pipeline Table" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/FinancialDetail"
                  render={(props) => (
                    <TheLayout title="Financial Detail" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/Metrics"
                  render={(props) => <TheLayout title="Metrics" {...props} />}
                />
                <Route
                  exact
                  path="/CohortAnalysis"
                  render={(props) => (
                    <TheLayout title="Cohort Analysis" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/MRRByES"
                  render={(props) => <TheLayout title="MRR By ES" {...props} />}
                />
                <Route
                  exact
                  path="/MRRByESUSD"
                  render={(props) => (
                    <TheLayout title="MRR By ES $" {...props} />
                  )}
                />

                <Route
                  exact
                  path="/ArchLaw/Analysis"
                  render={(props) => <TheLayout title="Analysis" {...props} />}
                />

                <Route
                  exact
                  path="/TheLegalDirector/FeeBreakdown"
                  render={(props) => (
                    <TheLayout title="Fee Breakdown" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/TheLegalDirector/Revenue"
                  render={(props) => <TheLayout title="Revenue" {...props} />}
                />
                <Route
                  exact
                  path="/TheLegalDirector/CacLtv"
                  render={(props) => (
                    <TheLayout title="CAC and LTV" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/TheLegalDirector/FinancialAnalysis"
                  render={(props) => (
                    <TheLayout title="Financial Analysis" {...props} />
                  )}
                />

                <Route
                  exact
                  path="/notifications/toaster"
                  render={(props) => <TheLayout {...props} />}
                />
                <Route
                  exact
                  path="/ContractRequest"
                  render={(props) => <TheLayoutWhiteLogin {...props} />}
                />
                <Route
                  exact
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
                <Route
                  exact
                  path="/CashManagement"
                  render={(props) => (
                    <TheLayout title="Cash Management" {...props} />
                  )}
                />

                <Route
                  exact
                  path="/TLC/KPIs"
                  render={(props) => <TheLayout title="KPIs" {...props} />}
                />
                <Route
                  exact
                  path="/TLC/KPIsFull"
                  render={(props) => <TheLayout title="KPIs" {...props} />}
                />
                <Route
                  exact
                  path="/TLC/Individuals"
                  render={(props) => (
                    <TheLayout title="Individuals" {...props} />
                  )}
                />

                <Route
                  exact
                  path="/Resignal/SalesAnalysis"
                  render={(props) => (
                    <TheLayout title="Sales Analysis" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/Resignal/ProjectResourcing"
                  render={(props) => (
                    <TheLayout title="Project Resourcing" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/Resignal/FinancialOutput"
                  render={(props) => (
                    <TheLayout title="Financial Output" {...props} />
                  )}
                />

                <Route
                  exact
                  path="/ForrestBrown/SalesCommission"
                  render={(props) => (
                    <TheLayout title="Sales Commission" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/ForrestBrown/SalesCommissionUser"
                  render={(props) => (
                    <TheLayout title="Sales Commission" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/ForrestBrown/ReferralCommission"
                  render={(props) => (
                    <TheLayout title="Referral Commission" {...props} />
                  )}
                />

                <Route
                  exact
                  path="/Demo/SalesPerformanceA"
                  render={(props) => (
                    <TheLayout title="Sales Performance A" {...props} />
                  )}
                />

                <Route
                  exact
                  path="/TTech/Commission"
                  render={(props) => (
                    <TheLayout title="Commission" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/TTech/DepartmentalSummary"
                  render={(props) => (
                    <TheLayout title="Departmental Summary" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/TTech/PandLTrend"
                  render={(props) => <TheLayout title="P&L Trend" {...props} />}
                />

                <Route
                  exact
                  path="/Reframe/Metrics"
                  render={(props) => <TheLayout title="Metrics" {...props} />}
                />
                <Route
                  exact
                  path="/Reframe/Time"
                  render={(props) => <TheLayout title="Time" {...props} />}
                />
                <Route
                  exact
                  path="/Reframe/Upload"
                  render={(props) => (
                    <TheLayout title="Upload Data" {...props} />
                  )}
                />

                <Route
                  exact
                  path="/OpexDetail"
                  render={(props) => (
                    <TheLayout title="Opex Detail" {...props} />
                  )}
                />

                {/* The Layout White */}
                <Route
                  exact
                  path="/LoginPage"
                  render={(props) => (
                    <TheLayoutWhite title="my portfolio" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/TwoFactorSetup"
                  render={(props) => (
                    <TheLayoutWhite
                      title="Two-Factor authentication Setup"
                      {...props}
                    />
                  )}
                />

                {/* The Layout White Login */}
                <Route
                  exact
                  path="/WelcomeEmailCreateAccount"
                  render={(props) => (
                    <TheLayoutWhiteLogin title="create account" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/CreateAccount"
                  render={(props) => (
                    <TheLayoutWhiteLogin title="create account" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/PasswordReset"
                  render={(props) => (
                    <TheLayoutWhiteLogin title="password reset" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/TermsOfBusiness"
                  render={(props) => <TheLayoutWhiteLogin {...props} />}
                />
                <Route
                  exact
                  path="/ContractAgreement"
                  render={(props) => <TheLayoutWhiteLogin {...props} />}
                />
                <Route
                  exact
                  path="/HandleLogin"
                  render={(props) => <TheLayoutWhiteLogin {...props} />}
                />
                <Route
                  exact
                  path="/PasswordResetForm"
                  render={(props) => (
                    <TheLayoutWhiteLogin title="reset password" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/InitialLogin"
                  render={(props) => (
                    <TheLayoutWhiteLogin title="login" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/TwoFactorCodeLogin"
                  render={(props) => (
                    <TheLayoutWhiteLogin
                      title="Two-Factor authentication Login"
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/PasswordResetForm"
                  render={(props) => (
                    <TheLayoutWhiteLogin title="reset password" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/XeroCallback"
                  render={(props) => <TheLayoutWhiteLogin {...props} />}
                />
                <Route
                  exact
                  path="/ConfirmAccount"
                  render={(props) => <TheLayoutWhiteLogin {...props} />}
                />
                <Route
                  exact
                  path="/VerifyEmail"
                  render={(props) => (
                    <TheLayoutWhiteLogin
                      title="license agreements"
                      {...props}
                    />
                  )}
                />

                <Route
                  exact
                  path="/TechQuiz"
                  render={(props) => (
                    <PlainLayout title="Tech Quiz" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/IMSQuiz"
                  render={(props) => (
                    <PlainLayout title="IMS Quiz" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/Admin/IRISControl"
                  render={(props) => <PlainLayout title="IRIS" {...props} />}
                />

                {/* The Layout Admin */}
                <Route
                  exact
                  path="/ManageDashboardsTable"
                  render={(props) => (
                    <TheLayoutAdmin title="Manage Dashboards" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/AddUser"
                  render={(props) => (
                    <TheLayoutAdmin title="add user" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/AccountManagement"
                  render={(props) => (
                    <TheLayoutAdmin title="account management" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/RemoveTwoFactor"
                  render={(props) => (
                    <TheLayoutAdmin
                      title="Remove two-factor authentication"
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/UpdatePassword"
                  render={(props) => (
                    <TheLayoutAdmin title="update password" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/CreateCompany"
                  render={(props) => (
                    <TheLayoutAdmin title="create company" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/AntiPhishingSetup"
                  render={(props) => (
                    <TheLayoutAdmin title="Anti-Phishing Setup" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/LicenseAgreements"
                  render={(props) => (
                    <TheLayoutAdmin title="license agreements" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/CompanyAgreements"
                  render={(props) => (
                    <TheLayoutAdmin title="license agreement" {...props} />
                  )}
                />

                <Route
                  exact
                  path="/HelpVideos"
                  render={(props) => (
                    <TheLayoutUserAdmin title="Knowledge hub" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/KnowledgeHub"
                  render={(props) => (
                    <TheLayoutUserAdmin title="Knowledge Hub" {...props} />
                  )}
                />

                {/* The Layout Manage */}
                <Route
                  exact
                  path="/ManagePAndLGroupings"
                  render={(props) => (
                    <TheLayoutManage title="Manage Groupings" {...props} />
                  )}
                />
                <Route
                  exact
                  path="/ManageUsers"
                  render={(props) => (
                    <TheLayoutManage title="manage users" {...props} />
                  )}
                />

                {/* The Layout User Admin */}
                <Route
                  exact
                  path="/Admin/ManageUsersAdmin"
                  render={(props) => (
                    <TheLayoutUserAdmin title="manage users admin" {...props} />
                  )}
                />

                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
              </Switch>
            </React.Suspense>
          </ScrollToTop>
        </HashRouter>
      </div>
    );
  }
}

export default App;
